<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <span>Filter by</span>
      <a-button type="link" @click="resetFields" >Reset Changes</a-button>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="commpany-taxonomy-details">
          <div class="commpany-taxonomy-details-form">
            <div class="title">Company Taxonomy</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sector"
                    label-in-value
                    v-model:value="modelRef.sectors"
                    :filter-option="false"
                    :options="sector_tags_options"
                    @search="fetchSectorData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Industry"
                    label-in-value
                    v-model:value="modelRef.industries"
                    :filter-option="false"
                    :options="industry_tags_options"
                    @search="fetchIndustryData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 95.5%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Vertical"
                    label-in-value
                    v-model:value="modelRef.verticals"
                    :filter-option="false"
                    :options="verticals_tags_options"
                    @search="fetchVerticalData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem; width: 100%"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    v-model:value="modelRef.practiceAreas"
                    class="filter-taxonomy-select filter-select"
                    mode="tags"
                    placeholder="Select Practice Area"
                    label-in-value
                    :filter-option="false"
                    :options="practice_area_tags_options"
                    @search="fetchPracticeAreaData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    v-model:value="modelRef.subpracticeAreas"
                    class="filter-taxonomy-select filter-select"
                    mode="tags"
                    placeholder="Select Sub Practice Area"
                    label-in-value
                    :filter-option="false"
                    :options="sub_practice_area_tags_options"
                    @search="fetchSubPracticeAreaData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 31%; margin-left: 1rem; width: 100%"
                class="filter-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-taxonomy-select filter-select"
                    mode="tags"
                    placeholder="Select Function"
                    label-in-value
                    v-model:value="modelRef.functions"
                    :filter-option="false"
                    :options="function_tags_options"
                    @search="fetchFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 31%">
                <a-form-item>
                  <a-select
                    class="filter-taxonomy-select filter-select"
                    mode="tags"
                    placeholder="Select Sub Function"
                    label-in-value
                    v-model:value="modelRef.subFunctions"
                    :filter-option="false"
                    :options="sub_function_tags_options"
                    @search="fetchSubFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 31%">
                <a-form-item>
                  <a-select
                    class="filter-taxonomy-select filter-select"
                    mode="tags"
                    placeholder="Select Sub Sub Function"
                    label-in-value
                    v-model:value="modelRef.subSubFunctions"
                    :filter-option="false"
                    :options="sub_sub_function_tags_options"
                    @search="fetchSubSubFunctionData;"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 31%; margin-left: 1rem"
                class="filter-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Company Type</span>
                  <a-select
                    class="filter-select filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="modelRef.types"
                    :filter-option="false"
                     placeholder="Select"
                    :options="company_types_tags_options"
                    @search="fetchCompanyTypeData"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div style="flex-basis: 31%" class="filter-form-elements">
                <a-form-item>
                  <span class="input-label">Company ID</span>
                  <a-input v-model:value="modelRef.companyId" placeholder="Enter" class="form-input" style="width: 96%"> </a-input>
                </a-form-item>
              </div>
              <div style="flex-basis: 31%" class="filter-form-elements">
                <a-form-item>
                  <span class="input-label">Company Name</span>
                  <a-input class="form-input" placeholder="Enter" v-model:value="modelRef.companyName" style="width: 96%"> </a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements"
                style="margin-left: 1rem; flex-basis: 22%"
              >
                <a-form-item>
                  <span class="input-label">From Date</span>
                  <a-date-picker
                    class="filter-date-input date-input"
                    style="padding-top: 0.65rem; margin-right: 1rem;width:90%;"
                    v-model:value="modelRef.fromDate"
                  ></a-date-picker>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 22%">
                <a-form-item>
                  <span class="input-label">To Date</span>
                  <a-date-picker
                    class="filter-date-input date-input"
                    style="padding-top: 0.65rem; margin-right: 1rem;width:90%;"
                    placeholder="To Date"
                    v-model:value="modelRef.toDate"
                  ></a-date-picker>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 48%"
              >
                <a-form-item>
                  <span class="input-label">Account Manager</span>
                  <a-select
                    class="filter-select"
                    label-in-value
                    placeholder="Select"
                    :options="account_managers_options"
                    v-model:value="modelRef.accountManagers"
                    style="width: 100%"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 40.5rem"
          />
          <div style="flex-basis: 9%">
            
          </div>
        </div>
        <div class="workflow-section">
          <div class="workflow-section-form">
            <div class="title">Workflow</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select"
                    label-in-value
                    v-model:value="modelRef.teams"
                    :filter-option="false"
                    :options="team_tags_options"
                    @search="fetchTeamsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Sub Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select"
                    label-in-value
                    v-model:value="modelRef.subTeams"
                    :filter-option="false"
                    :options="sub_team_tags_options"
                    @search="fetchSubTeamsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Procedure Stage</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    placeholder="Select"
                    v-model:value="modelRef.procedureStageValue"
                    :filter-option="false"
                    :options="procedure_stage_tags_options"
                    @search="fetchProcedureStageData;"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 22rem"
          />
          <div style="flex-basis: 9%">
            
          </div>
        </div>
      </a-form>
      <a-form>
        <div class="new-filter-section">
          <div class="new-filter-section-form">
            <div class="title">New Filter</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements"
                style="margin-left: 1rem; flex-basis: 50%"
              >
                <a-form-item>
                  <span class="input-label">Filter Name</span>
                  <a-input class="form-input" placeholder="Enter"> </a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Client Tagged To</span>
                  <a-select
                    class="filter-select"
                    label-in-value
                    :options="client_tag_tags_options"
                    v-model:value="modelRef.clientTagValue"
                    style="width: 100%"
                    placeholder="Select"
                    :filter-option="false"
                    mode="tags"
                    @search="fetchClientTagsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <span class="input-label">With Team Relationship Tag As</span>
                  <a-select
                    class="filter-select"
                    label-in-value
                    :options="tr_tags_options"
                    v-model:value="modelRef.trTagValue"
                    style="width: 100%"
                    placeholder="Select"
                    :filter-option="false"
                    mode="tags"
                    @search="fetchTRTagsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements"
                style="flex-basis: 97%; margin-left: 1.75rem"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <!-- <a-radio-group v-model:value="formState.resource"> -->
                  <a-radio-group v-model:value="relationshipRadioGroup">
                    <a-radio value="and">And</a-radio>
                    <a-radio value="or">Or</a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <span class="input-label">Client Not Tagged To</span>
                  <a-select
                    class="filter-select"
                    label-in-value
                    :options="client_not_tags_options"
                    v-model:value="modelRef.clientNotTagValue"
                    style="width: 100%"
                    placeholder="Select"
                    :filter-option="false"
                    mode="tags"
                    @search="fetchClientNotTagsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements"
                style="flex-basis: 97%; margin-left: 2rem"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <!-- <a-radio-group v-model:value="formState.resource"> -->
                  <a-radio-group v-model:value="clientRadioGroup">
                    <a-radio value="and">And</a-radio>
                    <a-radio value="or">Or</a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <span class="input-label">Client With Vacancy Tagged To</span>
                  <a-select
                    class="filter-select"
                    label-in-value
                    placeholder="Select"
                    :options="vacancy_tag_tags_options"
                    v-model:value="modelRef.vacancyTagValue"
                    style="width: 100%"
                    :filter-option="false"
                    mode="tags"
                    @search="fetchVacancyTagsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements"
                style="flex-basis: 97%; margin-left: 2rem"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <!-- <a-radio-group v-model:value="formState.resource"> -->
                  <a-radio-group v-model:value="vacancyRadioGroup">
                    <a-radio value="and">And</a-radio>
                    <a-radio value="or">Or</a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 46.5%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item style="margin-bottom: 0.35rem">
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sector"
                    label-in-value
                    v-model:value="modelRef.filterSectorValue"
                    :filter-option="false"
                    :options="filter_setor_tags_options"
                    @search="fetchFilterSectorData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46.5%">
                <a-form-item style="margin-bottom: 0.35rem">
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="modelRef.filterIndustryValue"
                    :filter-option="false"
                    :options="filter_industry_tags_options"
                    @search="fetchFilterIndustryData"
                    placeholder="Select Industry"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-button class="save-new-filter" style="color:'white;'">Save</a-button>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 45rem"
          />
          <div style="flex-basis: 9%">
            
          </div>
        </div>
        <div class="footer" style="display:flex;margin-top:10px;">
          <div style="margin-left:auto;">
            <a-button
            type="link"
            style="
              color: rgb(76, 81, 85);
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            key="back"
            @click="closeFilter"
            >Cancel</a-button
          >
          </div>
          <div>
            <a-button
            key="submit"
            style="
              background: RGB(228, 129, 106);
              color: #ffffff;
              border-radius: 15px;
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            @click="accountFilterModalOk"
            >Apply Filter</a-button
          >
        </div>
          </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { Form } from "ant-design-vue";
import { defineComponent, ref, onMounted, reactive } from "vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";
const useForm = Form.useForm;
export default defineComponent({
  setup(props,context) {
    const relationshipRadioGroup = ref("and");
    const clientRadioGroup = ref("and");
    const vacancyRadioGroup = ref("and");
    let modelRef = reactive({
      companyName : ref(''),
      companyId : ref(''),
      sectors: ref([]),
      industries: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subpracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      types: ref([]),
      degree: ref([]),
      institutes: ref([]),
      resumeType: ref([]),
      accountManagers: ref([]),
      teams:ref([]),
      subTeams:ref([]),
      fromDate:ref(""),
      toDate:ref(""),
      
    });
    let sector_tags_options = ref([]);
    let industry_tags_options = ref([]);
    let verticals_tags_options = ref([]);
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let function_tags_options = ref([]);
    let sub_function_tags_options = ref([]);
    let sub_sub_function_tags_options = ref([]);
    let company_types_tags_options = ref([]);
    let account_managers_options = ref([]);
    let team_tags_options = ref([]);
    let sub_team_tags_options = ref([]);
    let procedure_stage_tags_options = ref([]);
    let client_tag_tags_options = ref([]);
    let tr_tags_options = ref([]);
    let client_not_tags_options = ref([]);
    let vacancy_tag_tags_options = ref([]);
    let filter_setor_tags_options = ref([]);
    let filter_industry_tags_options = ref([]);
    let payload = {
      sectors: ref([]),
      industries: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      types: ref([]),
      degree: ref([]),
      institutes: ref([]),
      resumeType: ref([]),
      designation: "",
      vacancyFilter: "",
    };
    const { resetFields } = useForm(modelRef);
    function closeFilter() {
      context.emit("closeFilter", false);
    }
    function resetFilter(){
      modelRef.companyName = ref(''),
      modelRef.sectors= ref([]),
      modelRef.industries= ref([]),
      modelRef.verticals= ref([]),
      modelRef.practiceAreas= ref([]),
      modelRef.subpracticeAreas= ref([]),
      modelRef.functions= ref([]),
      modelRef.subFunctions= ref([]),
      modelRef.subSubFunctions= ref([]),
      modelRef.types= ref([]),
      modelRef.degree= ref([]),
      modelRef.institutes= ref([]),
      modelRef.resumeType= ref([]),
      modelRef.fromDate=ref(""),
      modelRef.toDate=ref(""),
      modelRef.accountManagers= ref([])
    }
    function accountFilterModalOk() {
      let tempObject = Utils.deepCopyObject(payload);
      modelRef.companyName
        ? (tempObject.companyName = modelRef.companyName)
        : delete tempObject.companyName;
      modelRef.companyId
        ? (tempObject.companyId = modelRef.companyId)
        : delete tempObject.companyId;
        
      modelRef.sectors.length > 0
        ? (tempObject.sectors = modelRef.sectors.map((x) => x.value))
        : delete tempObject.sectors;
      modelRef.accountManagers.length >= 0
        ? (tempObject.accountManagers = modelRef.accountManagers.map((x) => x.value))
        : delete tempObject.accountManagers;
      modelRef.industries.length > 0
        ? (tempObject.industries = modelRef.industries.map((x) => x.value))
        : delete tempObject.industries;
      modelRef.verticals.length > 0
        ? (tempObject.verticals = modelRef.verticals.map((x) => x.value))
        : delete tempObject.verticals;
      modelRef.practiceAreas.length > 0
        ? (tempObject.practiceAreas = modelRef.practiceAreas.map((x) => x.value))
        : delete tempObject.practiceAreas;
      modelRef.teams.length > 0
        ? (tempObject.teams = modelRef.teams.map((x) => x.value))
        : delete tempObject.teams;
      modelRef.subTeams.length > 0
        ? (tempObject.subTeams = modelRef.subTeams.map((x) => x.value))
        : delete tempObject.subTeams;
        
      modelRef.functions.length > 0
        ? (tempObject.functions = modelRef.functions.map((x) => x.value))
        : delete tempObject.functions;
      modelRef.subFunctions.length > 0
        ? (tempObject.subFunctions = modelRef.subFunctions.map((x) => x.value))
        : delete tempObject.subFunctions;
      modelRef.subSubFunctions.length > 0
        ? (tempObject.subSubFunctions = modelRef.subSubFunctions.map((x) => x.value))
        : delete tempObject.subSubFunctions;
      modelRef.types.length > 0
        ? (tempObject.types = modelRef.types.map((x) => x.value))
        : delete tempObject.types;
      modelRef.degree.length > 0
        ? (tempObject.degree = modelRef.degree.map((x) => x.value))
        : delete tempObject.degree;
      modelRef.institutes.length > 0
        ? (tempObject.institutes = modelRef.institutes.map((x) => x.value))
        : delete tempObject.institutes;
      modelRef.resumeType.length > 0
        ? (tempObject.resumeType = modelRef.resumeType.map((x) => x.value))
        : delete tempObject.resumeType;
      modelRef.fromDate
        ? (tempObject.fromDate = Utils.displayYearFirst(modelRef.fromDate))
        : delete tempObject.fromDate;
      modelRef.toDate
        ? (tempObject.toDate = Utils.displayYearFirst(modelRef.toDate))
        : delete tempObject.toDate;  
      
      context.emit("accountFilterModalOk", tempObject, false);
      // resetFilter();
    }
    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          verticals_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubPracticeAreaData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        mode: "AUTOCOMPLETE",
        types: ["SUB_PRACTICE_AREA"],
      };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["COMPANY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          company_types_tags_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchAccountManagersData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        types: ["MEMBER"],
      };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          account_managers_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["SUB_TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          sub_team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchProcedureStageData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          procedure_stage_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchClientTagsData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        types: ["MEMBER"],
      };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          client_tag_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchTRTagsData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        mode: "AUTOCOMPLETE",
        types: ["RAG"],
      };
      Services.getRmStatusDetail(payload)
        .then((response) => {
          const { data } = response;
          tr_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchClientNotTagsData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        types: ["MEMBER"],
      };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          client_not_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVacancyTagsData = (value) => {
      let payload = {};
      let q = "";
      payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        types: ["MEMBER"],
      };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          vacancy_tag_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchFilterSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          filter_setor_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchFilterIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          filter_industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    onMounted(() => {
      fetchVerticalData();
      fetchPracticeAreaData();
      fetchSubPracticeAreaData();
      fetchFunctionData();
      fetchSubFunctionData();
      fetchSubSubFunctionData();
      fetchSectorData();
      fetchIndustryData();
      fetchCompanyTypeData();
      fetchTeamsData();
      fetchSubTeamsData();
      fetchAccountManagersData();
      fetchProcedureStageData();
      fetchClientTagsData();
      fetchTRTagsData();
      fetchClientNotTagsData();
      fetchVacancyTagsData();
      fetchFilterSectorData();
      fetchFilterIndustryData();
    });

    return {
      // All select elements value refs
      modelRef,
      resetFields,
      resetFilter,
      accountFilterModalOk,
      closeFilter,
      // All select items options
      sector_tags_options,
      industry_tags_options,
      verticals_tags_options,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      function_tags_options,
      sub_function_tags_options,
      sub_sub_function_tags_options,
      company_types_tags_options,
      account_managers_options,
      team_tags_options,
      sub_team_tags_options,
      procedure_stage_tags_options,
      client_tag_tags_options,
      tr_tags_options,
      client_not_tags_options,
      vacancy_tag_tags_options,
      filter_setor_tags_options,
      filter_industry_tags_options,
      // Select Api call Funtions

      fetchVerticalData,
      fetchPracticeAreaData,
      fetchSubPracticeAreaData,
      fetchFunctionData,
      fetchSubFunctionData,
      fetchSubSubFunctionData,
      fetchSectorData,
      fetchIndustryData,
      fetchCompanyTypeData,
      fetchTeamsData,
      fetchSubTeamsData,
      fetchAccountManagersData,
      fetchProcedureStageData,
      fetchClientTagsData,
      fetchTRTagsData,
      fetchClientNotTagsData,
      fetchVacancyTagsData,
      fetchFilterSectorData,
      fetchFilterIndustryData,
      // And Or Radio Button Group
      relationshipRadioGroup,
      clientRadioGroup,
      vacancyRadioGroup,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../styles/accountFilter.scss";
</style>
