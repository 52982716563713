<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <span>Filter by</span>
      <a-button type="link" @click="resetFields">Reset Changes</a-button>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="filter-personal-details">
          <div class="filter-personal-details-form">
            <div class="title">Client Person Details</div>
            <div class="form-sub-section">
              <div class="filter-form-elements" style="flex-basis: 33%">
                <a-form-item>
                  <span class="input-label">Client Type</span>
                  <a-select
                    class="filter-select"
                    v-model:value="modelRef.companyType"
                    :filter-option="false"
                    :options="companyTypesOptions"
                    @search="fetchCompanyTypeData"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements center-element"
                style="flex-basis: 33%"
              >
                <a-form-item>
                  <span class="input-label">RM CIC</span>
                  <a-select
                    show-search
                    showArrow
                    class="filter-select"
                    style="width: 100%"
                    v-model:value="modelRef.cic"
                    :filter-option="false"
                    :options="lead_cic_options"
                    @search="fetchCIC"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div style="flex-basis: 33%" class="filter-form-elements">
                <a-form-item>
                  <span class="input-label">Client Name</span>
                  <a-select
                    show-search
                    showArrow
                    class="filter-select"
                    style="width: 100%"
                    v-model:value="modelRef.companyId"
                    :filter-option="false"
                    :options="company_name_options"
                    @search="getCompanyName"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="filter-form-elements" style="flex-basis: 33%">
                <a-form-item>
                  <span class="input-label">Designation</span>
                  <a-input
                    v-model:value="modelRef.designation"
                    class="form-input"
                  ></a-input>
                </a-form-item>
              </div>
              <div
                style="flex-basis: 33%; width: 100%"
                class="filter-form-elements center-element"
              >
                <a-form-item>
                  <span class="input-label">Department</span>
                  <a-select
                    show-search
                    showArrow
                    class="filter-select"
                    style="width: 100%"
                    v-model:value="modelRef.department"
                    :filter-option="false"
                    :options="departmentOptions"
                    @search="getDepartments"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 33%">
                <a-form-item>
                  <span class="input-label">Client Person Name</span>
                  <a-input
                    v-model:value="modelRef.name"
                    class="form-input"
                    placeholder="Enter Name"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="filter-form-elements" style="flex-basis: 33%">
                <a-form-item>
                  <span class="input-label">Mobile</span>
                  <a-input
                    v-model:value="modelRef.mobile"
                    class="form-input"
                    placeholder="Mobile"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements center-element"
                style="flex-basis: 33%"
              >
                <a-form-item>
                  <span class="input-label">Meeting From</span>
                  <a-date-picker
                    class="date-input"
                    v-model:value="modelRef.meetingFrom"
                    style="padding-top: 0.65rem; width: 100%"
                  ></a-date-picker>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 33%">
                <a-form-item>
                  <span class="input-label">Meeting To</span>
                  <a-date-picker
                    class="date-input"
                    v-model:value="modelRef.meetingTo"
                    style="padding-top: 0.65rem; width: 100%"
                    placeholder="To Date"
                  ></a-date-picker>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 100%;"
                class="filter-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Sub Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    label-in-value
                    v-model:value="modelRef.subTeams"
                    :filter-option="false"
                    :options="sub_team_tags_options"
                    @search="fetchSubTeamsData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 33%;"
                class="filter-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Sector</span>
                  <a-select
                    showArrow
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sector"
                    label-in-value
                    v-model:value="modelRef.sectors"
                    :filter-option="false"
                    :options="sector_tags_options"
                    @search="fetchSectorData"
                  ></a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements center-element"
                style="flex-basis: 33%"
              >
                <a-form-item>
                  <span class="input-label">Industry</span>
                  <a-select
                    showArrow
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Industry"
                    label-in-value
                    v-model:value="modelRef.industries"
                    :filter-option="false"
                    :options="industry_tags_options"
                    @search="fetchIndustryData"
                  ></a-select>
                </a-form-item>
              </div>
              <div style="flex-basis: 33%" class="filter-form-elements">
                <a-form-item>
                  <span class="input-label">Verticals</span>
                  <a-select
                    showArrow
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Vertical"
                    label-in-value
                    v-model:value="modelRef.verticals"
                    :filter-option="false"
                    :options="verticals_tags_options"
                    @search="fetchVerticalData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <a-button
            type="link"
            style="
              color: rgb(76, 81, 85);
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            key="back"
            @click="closeFilter"
            >Cancel</a-button
          >
          <a-button
            key="submit"
            style="
              background: RGB(228, 129, 106);
              color: #ffffff;
              border-radius: 15px;
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            @click="filterModalOk"
            >Apply Filter</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { Form } from "ant-design-vue";
import { defineComponent, ref, onMounted, reactive } from "vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";

const useForm = Form.useForm;

export default defineComponent({
  setup(props, context) {
    let company_name_options = ref([]);
    let lead_cic_options = ref([]);
    let sector_tags_options = ref([]);
    let industry_tags_options = ref([]);
    let verticals_tags_options = ref([]);
    let companyTypesOptions = ref([]);
    let sub_team_tags_options = ref([]);
    let departmentOptions = ref([]);

    let modelRef = reactive({
      companyType: ref(""),
      cic: ref(),
      companyId: ref(""),
      designation: "",
      department: ref(),
      name: "",
      mobile: "",
      meetingFrom: "",
      meetingTo: "",
      subTeams: ref([]),
      sectors: ref([]),
      verticals: ref([]),
      industries: ref([]),
    });
    let payload = {
      companyType: ref(""),
      cic: ref(),
      companyId: ref(""),
      designation: "",
      department: ref(),
      name: "",
      mobile: "",
      meetingFrom: "",
      meetingTo: "",
      subTeams: ref([]),
      sectors: ref([]),
      verticals: ref([]),
      industries: ref([]),
    };
    const { resetFields } = useForm(modelRef);

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          verticals_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["COMPANY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          companyTypesOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getCompanyName = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data[0].accounts.map((x) => {
            tempData.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(company_name_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["SUB_TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          sub_team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCIC = (value) => {
      let user_listing_payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          lead_cic_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getDepartments = (value) => {
      Services.getDepartmentsDetail({ q: value })
        .then((response) => {
          const { data } = response;
          departmentOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    onMounted(() => {
      fetchVerticalData();
      fetchSectorData();
      fetchIndustryData();
      fetchCompanyTypeData();
      getCompanyName();
      fetchSubTeamsData();
      fetchCIC();
      getDepartments();
    });

    function closeFilter() {
      context.emit("closeFilter", false);
    }

    function filterModalOk() {
      let tempObject = Utils.deepCopyObject(payload);
      modelRef.companyType
        ? (tempObject["companyType"] = modelRef.companyType)
        : delete tempObject.companyType;
      modelRef.cic ? (tempObject.cic = modelRef.cic) : delete tempObject.cic;
      modelRef.companyId
        ? (tempObject["companyId"] = modelRef.companyId)
        : delete tempObject.companyId;
      modelRef.designation
        ? (tempObject["designation"] = modelRef.designation)
        : delete tempObject.designation;
      modelRef.department
        ? (tempObject["department"] = modelRef.department)
        : delete tempObject.department;
      modelRef.name
        ? (tempObject["name"] = modelRef.name)
        : delete tempObject.name;
      modelRef.mobile
        ? (tempObject["mobile"] = modelRef.mobile)
        : delete tempObject.mobile;
      modelRef.meetingFrom
        ? (tempObject.meetingFrom = Utils.displayYearFirst(
            modelRef.meetingFrom
          ))
        : delete tempObject.meetingFrom;
      modelRef.meetingTo
        ? (tempObject.meetingTo = Utils.displayYearFirst(modelRef.meetingTo))
        : delete tempObject.meetingTo;
      modelRef.subTeams.length > 0
        ? (tempObject.subTeams = modelRef.subTeams.map((x) => x.value))
        : delete tempObject.subTeams;
      modelRef.sectors.length > 0
        ? (tempObject.sectors = modelRef.sectors.map((x) => x.value))
        : delete tempObject.sectors;
      modelRef.verticals.length > 0
        ? (tempObject.verticals = modelRef.verticals.map((x) => x.value))
        : delete tempObject.verticals;
      modelRef.industries.length > 0
        ? (tempObject.industries = modelRef.industries.map((x) => x.value))
        : delete tempObject.industries;
      context.emit("filterModalOk", tempObject, false);
      // resetFilter();
    }

    function resetFilter() {
      modelRef.companyType = ref("");
      modelRef.cic = ref();
      modelRef.companyId = ref("");
      modelRef.designation = "";
      modelRef.department = ref();
      modelRef.name = "";
      modelRef.mobile = "";
      modelRef.meetingFrom = "";
      modelRef.meetingTo = "";
      modelRef.subTeams = ref([]);
      modelRef.sectors = ref([]);
      modelRef.verticals = ref([]);
      modelRef.industries = ref([]);
    }

    return {
      modelRef,
      resetFields,
      closeFilter,
      filterModalOk,
      resetFilter,

      // All select items options
      lead_cic_options,
      sector_tags_options,
      industry_tags_options,
      verticals_tags_options,
      companyTypesOptions,
      company_name_options,
      sub_team_tags_options,
      departmentOptions,

      // Select Api call Funtions
      fetchVerticalData,
      fetchSectorData,
      fetchIndustryData,
      fetchCompanyTypeData,
      fetchCIC,
      getCompanyName,
      getDepartments,
      fetchSubTeamsData,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/clientFilter.scss";
</style>
