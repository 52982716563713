<template>
  <div>
    <div style="display: flex">
      <div
        class="companies-listing-header-and-listing-wrapper"
        style="margin-right: 10px; padding-top: 20px"
        :style="[rightSideBarVisibility ? ' width : 73%' : ' width : 100%']"
      >
        <div class="companies-listing-users-listings">
          <a-tabs
            type="card"
            v-model:activeKey="activeKey"
            class="company-list-tabs"
            @change="onChangeTab(activeKey)"
          >
            <a-tab-pane key="accounts" tab="Client">
              <a-divider />
              <div class="companies-listing-search-wrapper">
                <div class="companies-listing-search-box-wrapper">
                  <div
                    class="companies-listing-header-search-wrapper"
                    style="display: flex; margin-top: 25px; margin-bottom: 30px"
                  >
                    <a-input-search
                      style="
                        border-radius: 15px;
                        margin-left: 2rem;
                        height: 44px;
                        font-size: 22px;
                      "
                      class="companies-listing-header-search-box"
                      placeholder="Search"
                      v-model:value="accountSearchValue"
                      @search="searchAccount(accountSearchValue)"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <a-button class="btn-filter" @click="showAccountFilters">
                      <strong>
                        <FilterOutlined :style="{ fontSize: '18px' }"
                      /></strong>
                      <strong> Filter</strong>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="table-color-data">
                <div style="padding: 0 10px">
                  <img
                    style="padding: 2%"
                    src="../../../assets/Rectangle_984.png"
                  />
                  <span class="no_rm"> No RM</span> &nbsp;
                  <img
                    style="padding: 2%"
                    src="../../../assets/Rectangle_983.png"
                  />
                  <span style="color: #fdcd39" class="rm_not_within"
                    >RM Not Within Team</span
                  >&nbsp;
                  <img
                    style="padding: 2%"
                    src="../../../assets/Rectangle_125.png"
                  />
                  <span class="rm_found">RM From Table</span>
                </div>
              </div>
              <a-spin
                :spinning="loading"
                :style="[
                  rightSideBarVisibility
                    ? ' width: 65%; padding-top: 80px; z-index: 9; position: fixed'
                    : 'width: 90%; padding-top: 80px; z-index: 9; position: fixed',
                ]"
                :indicator="indicator"
              />
              <a-table
                class="companies-listing-table"
                :row-selection="accountrowSelection"
                style="cursor: pointer"
                :columns="companyListing"
                :data-source="accountListingData"
                :pagination="false"
                :row-key="(record) => record.id"
              >
                <template #cic="{ text }">
                  <span
                    style="color: black"
                    v-for="cicData in text"
                    :key="cicData.id"
                    >{{ cicData.name ? cicData.name : "-" }}</span
                  >
                  <span v-if="text.length < 1"> - </span>
                </template>
                <template #subTeams="{ text }">
                  <div v-for="subTeam in text" :key="subTeam.id">
                    <a-tag
                      :style="{
                        backgroundColor: '#' + subTeam.rag.color,
                        color: 'rgba(0,0,0)',
                      }"
                      v-if="
                        subTeam.rag &&
                        subTeam.rag.id &&
                        subTeam.rag.color != '010101'
                      "
                      >{{ subTeam.name ? subTeam.name : "-" }}
                    </a-tag>
                    <span v-else-if="subTeam.name">{{
                      subTeam.name ? subTeam.name : "-"
                    }}</span>
                    <span v-else> - </span>
                  </div>
                </template>
                <template #companyName="{ text }">
                  <div style="display: flex">
                    <div style="margin-right: 5px; padding-top: 5px">
                      <a-avatar>
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                    </div>
                    <div>
                      <div
                        class="company-name"
                        @click="fetchAccountId(text.id)"
                      >
                        {{ text.name }}
                      </div>
                      <div>
                        <u
                          ><a class="company-listing-company-id">{{
                            text.companyId
                          }}</a></u
                        >
                        &nbsp;
                        <BarChartOutlined
                          @click="getAccountId(text.id)"
                          style="color: black"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template #menuTitle>
                  <a-button class="icon-btn" @click="showModal" type="link">
                    <MenuOutlined />
                  </a-button>
                  <a-modal
                    v-model:visible="visible"
                    ok-text="Save"
                    cancel-text=" "
                    :closable="false"
                    @ok="hideModal"
                  >
                  </a-modal>
                  <a-modal
                    v-model:visible="widgetvisible"
                    ok-text=" "
                    cancel-text=" "
                    footer=""
                    :closable="false"
                    @ok="hideModal"
                    destroyOnClose
                  >
                    <Widget :id="TaxoId" />
                  </a-modal>
                </template>
                <template #accountManager="{ text }">
                  <div
                    class="account-manager"
                    v-for="accountManagers in text"
                    :key="accountManagers.id"
                  >
                    {{ accountManagers.name ? accountManagers.name : "-" }}
                  </div>
                </template>
                <template #taxonomy>
                  <img
                    style="padding: 2%"
                    src="../../../assets/Rectangle_984.png"
                  /><img src="../../../assets/Rectangle_983.png" /><img
                    style="padding: 2%"
                    src="../../../assets/Rectangle_125.png"
                  />
                </template>
                <template #comments="{ text }">
                  <span style="opacity: 0.6; font-family: 'Poppins'">{{
                    text
                  }}</span>
                </template>
                <template #rmtagged="{ text }">
                  <a-tag
                    v-if="text !== '-'"
                    :color="'#' + text"
                    class="rmtagged"
                  ></a-tag>
                  <span v-else>{{ text }}</span>
                </template>
              </a-table>
              <div class="view-more-btn">
                <a-button
                  v-if="accountListingData.length > 9 && accountCount[0].total > accountListingData.length"
                  type="link"
                  shape="round"
                  class="borderless-btn"
                  @click="viewMoreData(accountSearchValue)"
                >
                  <template #icon>
                    <PlusCircleOutlined />
                    View more
                  </template>
                </a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="clientPerson" tab="Client Person">
              <a-divider />
              <div class="companies-listing-search-wrapper">
                <div class="companies-listing-search-box-wrapper">
                  <div
                    class="companies-listing-header-search-wrapper"
                    style="
                      display: flex;
                      margin-top: 1.5rem;
                      margin-bottom: 1.875rem;
                    "
                  >
                    <a-input-search
                      style="
                        border-radius: 15px;
                        margin-left: 2rem;
                        height: 44px;
                        font-size: 22px;
                      "
                      class="companies-listing-header-search-box"
                      placeholder="Search"
                      v-model:value="clientPersonSearchValue"
                      @search="searchClientPerson(clientPersonSearchValue)"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <a-button
                      class="btn-filter"
                      @click="showClientPersonFilter"
                    >
                      <FilterOutlined :style="{ fontSize: '18px' }" />Filter
                    </a-button>
                  </div>
                </div>
              </div>
              <a-spin
                :spinning="Clientloading"
                :style="[
                  rightSideBarVisibility
                    ? ' width: 65%; padding-top: 100px; z-index: 9; position: fixed'
                    : 'width: 90%; padding-top: 100px; z-index: 9; position: fixed',
                ]"
                :indicator="indicator"
              />
              <a-table
                :row-selection="clientrowSelection"
                class="companies-listing-table"
                :columns="clientPersonListing"
                :data-source="clientPersonData"
                :pagination="false"
                :rowKey="(record) => record.clientPersonName.id"
                style="padding: 3.5rem 1.25rem 1.25rem 1.25rem"
              >
                <template #clientPersonName="{ text }">
                  <div
                    class="client-person-name"
                    @click="fetchClientId(text.id)"
                  >
                    {{ text.name }}
                  </div>
                  <div class="client-person-designation">
                    {{ text.designation }}
                  </div>
                </template>

                <template #companyName="{ text }">
                  <div style="display: flex">
                    <div style="margin-right: 5px; padding-top: 5px">
                      <a-avatar>
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                    </div>
                    <div>
                      <div
                        class="client-person-company-name"
                        @click="fetchAccountId(text.id)"
                      >
                        {{ text.name }}
                      </div>
                      <div>
                        <u class="company-listing-company-id">
                          {{ text.companyId }}
                        </u>
                        &nbsp;
                        <BarChartOutlined @click="getAccountId(text.id)" />
                      </div>
                    </div>
                  </div>
                </template>

                <template #Rm="{ text }">
                  <div v-for="rm in text" :key="rm.id">
                    <a-tag
                      :style="{
                        backgroundColor: '#' + rm.rag.color,
                        color: 'rgba(0,0,0)',
                      }"
                      v-if="Object.keys(rm.rag).length > 0"
                      >{{ rm.name ? rm.name : "-" }}</a-tag
                    >
                    <span v-else-if="rm.name">{{ rm.name }}</span>
                    <span v-else>-</span>
                  </div>
                </template>

                <template #subTeam="{ text }">
                  <div v-for="subTeam in text" :key="subTeam.id">
                    <a-tag
                      :style="{
                        backgroundColor: '#' + subTeam.rag.color,
                        color: 'rgba(0,0,0)',
                      }"
                      v-if="subTeam.rag"
                      >{{ subTeam.name ? subTeam.name : "-" }}</a-tag
                    >
                    <span v-else-if="subTeam.name">{{ subTeam.name }}</span>
                    <span v-else>-</span>
                  </div>
                </template>

                <template #callNumber="{ text }">
                  <span class="client-person-call-column">
                    <u>{{ text }}</u>
                  </span>
                </template>

                <template #comments="{ text }">
                  <span class="client-person-comments">{{ text }}</span>
                </template>

                <!-- <template #subTeam> </template> -->
                <template #menuTitle>
                  <a-button @click="showModal" type="link">
                    <MenuOutlined class="icon-btn" />
                  </a-button>
                  <a-modal
                    v-model:visible="visible"
                    ok-text="Save"
                    cancel-text=" "
                    :closable="false"
                    @ok="hideModal"
                  >
                  </a-modal>
                </template>
              </a-table>
              <div class="view-more-btn">
                <div v-if="clientPersonViewMore">
                  <a-button
                    type="link"
                    shape="round"
                    class="borderless-btn"
                    @click="fetchMoreClientPersons()"
                  >
                    <template #icon>
                      <PlusCircleOutlined />
                      View More
                    </template>
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
          <!-- Account Filter Modal -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="accountFilterVisibility"
            :closable="false"
            centered
            :footer="null"
            @ok="accountFilterModalOk"
            width="1200px"
          >
            <div>
              <div style="width=80%">
                <accountFilter
                  @closeFilter="accountFilterModalCancel"
                  @accountFilterModalOk="accountFilterModalOk"
                />
              </div>
            </div>
          </a-modal>
          <!-- Client Person Filter Modal -->
          <a-modal
            class="filter-modal"
            title=""
            v-model:visible="clientPersonFilterVisibility"
            :closable="false"
            centered
            width="800px"
          >
            <div>
              <div style="width=80%">
                <clientPersonFilter
                  @closeFilter="clientPersonFilterModalCancel"
                  @filterModalOk="clientPersonFilterModalOk"
                />
              </div>
            </div>
            <template #footer>
              <a-button> </a-button>
            </template>
          </a-modal>
        </div>
      </div>
      <div>
        <LeftCircleTwoTone
          v-if="!rightSideBarVisibility"
          @click="showRightSideBar"
          style="color: blue; font-size: 1.5rem"
        />
        <RightCircleTwoTone
          v-if="rightSideBarVisibility"
          @click="showRightSideBar"
          style="
            font-size: 1.5rem;
            position: fixed;
            z-index: 9;
            transform: translateX(-10px);
          "
        />
      </div>

      <div v-if="rightSideBarVisibility" class="action-bar" style="flex: 1">
        <ActionBar />
      </div>
    </div>
  </div>
</template>
<script>
import accountFilter from "./accountFilter.vue";
import clientPersonFilter from "./clientFilter.vue";
import Services from "@/services/apis";
import { useStore } from "vuex";
import Utils from "../../../utils";
import { useRouter } from "vue-router";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { defineComponent, h } from "vue";
import {
  FilterOutlined,
  PlusCircleOutlined,
  UserOutlined,
  BarChartOutlined,
  MenuOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
} from "@ant-design/icons-vue";
import Widget from "./widget.vue";

import { ref, onMounted, onUnmounted, reactive } from "vue";
import ActionBar from "./rightNav.vue";

const visible = ref(false);
const filterVisible = ref(false);
const widgetvisible = ref(false);
const clientPersonFilterVisibility = ref(false);

const showModal = () => {
  visible.value = true;
};
const showFilterModal = () => {
  filterVisible.value = true;
};

const widgetShowModal = () => {
  widgetvisible.value = true;
};

const hideModal = () => {
  visible.value = false;
};
let accountListingData = reactive([]);
let clientPersonData = reactive([]);
let currentPageNum = 0;
let pageNumberClientPerson = 0;
let loading = ref(true);
let Clientloading = ref(true);
const companyListing = [
  {
    title: "Client",
    key: "companyName",
    dataIndex: "companyName",
    align: "left",
    checked: ref(false),
    slots: { customRender: "companyName" },
  },

  {
    title: "Client Manager",
    key: "accountManager",
    dataIndex: "accountManager",
    align: "left",
    checked: ref(false),
    slots: { customRender: "accountManager" },
  },
  {
    title: "RM",
    key: "rmtagged",
    dataIndex: "rmtagged",
    align: "left",
    checked: ref(false),
    width: 30,
    slots: { customRender: "rmtagged" },
  },
  {
    title: "Sub Team",
    dataIndex: "subTeam",
    slots: { customRender: "subTeams" },
    key: "subTeam",
    align: "left",
    checked: ref(false),
  },
  {
    title: "CIC",
    dataIndex: "cic",
    key: "cic",
    checked: ref(false),
    slots: { customRender: "cic" },
  },
  {
    title: "Taxonomy",
    dataIndex: "taxonomy",
    key: "taxonomy",
    align: "center",
    width: 100,
    checked: ref(false),
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    slots: { customRender: "comments" },
    align: "center",
    checked: ref(false),
  },
  {
    width: 0,
    dataIndex: "menu",
    key: "menu",
    checked: ref(false),
    slots: {
      title: "menuTitle",
      customRender: "menu",
    },
  },
];
const clientPersonListing = [
  {
    title: "Client Person",
    align: "left",
    dataIndex: "clientPersonName",
    key: "clientPersonName",
    slots: { customRender: "clientPersonName" },
  },
  {
    title: "Client",
    dataIndex: "companyName",
    key: "companyName",
    align: "left",
    slots: { customRender: "companyName" },
  },
  {
    title: "RM",
    dataIndex: "Rm",
    key: "Rm",
    slots: { customRender: "Rm" },
  },
  {
    title: "Sub Team",
    dataIndex: "subTeam",
    key: "subTeam",
    slots: { customRender: "subTeam" },
  },
  {
    title: "Last Meeting",
    dataIndex: "lastMeeting",
    key: "lastMeeting",
  },

  {
    title: "Email ID",
    dataIndex: "emailId",
    key: "emailId",
    slots: { customRender: "emailId" },
  },
  {
    title: "Call",
    dataIndex: "callNumber",
    key: "callNumber",
    slots: { customRender: "callNumber" },
    align: "center",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    slots: { customRender: "comments" },
    align: "center",
  },
  {
    dataIndex: "menu",
    key: "menu",
    slots: {
      title: "menuTitle",
      customRender: "menu",
    },
  },
];
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "50px",
    margin: "0 auto",
  },
  spin: ref(loading),
});
const data = [];
let rightSideBarVisibility = ref(false);
const showRightSideBar = () => {
  rightSideBarVisibility.value = !rightSideBarVisibility.value;
};
export default {
  components: {
    FilterOutlined,
    PlusCircleOutlined,
    UserOutlined,
    Widget,
    BarChartOutlined,
    MenuOutlined,
    accountFilter,
    clientPersonFilter,
    ActionBar,
    LeftCircleTwoTone,
    RightCircleTwoTone,
  },
  setup() {
    let rmData = reactive([]);
    let accountSearchValue = ref("");
    let clientPersonSearchValue = ref("");
    let clientPersonViewMore = ref(false);
    let clientPersonListCount = ref();
    let clientPersonFilterObject;
    const router = useRouter();
    let listDataObj;
    const store = useStore();
    const accountrowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        store.dispatch("clientStore/updateSelectedclients", selectedRowKeys);
        selectedRowKeys = [];
      },
    };
    const clientrowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };

    const viewMoreData = (value) => {
      currentPageNum++;
      if (accountSearchValue.value) {
        getAccountData(accountSearchValue.value);
      } else if (listDataObj) {
        getAccountData(listDataObj);
      } else {
        getAccountData();
      }
    };
    const fetchAccountId = (id) => {
      router.push(`/accounts/${id}/detail`);
    };
    const fetchClientId = (id) => {
      router.push(`/client_persons/${id}/detail`);
    };

    const searchAccount = (value) => {
      currentPageNum = 0;
      getAccountData(value);
    };

    const onChangeTab = (e) => {
      if (e == "accounts") {
        if (accountListingData.length === 0) {
          getAccountData();
        }
      }
      if (e == "clientPerson") {
        if (clientPersonData.length === 0) {
          fetchClientPersonListing();
        }
      }
    };

    const fetchMoreClientPersons = () => {
      pageNumberClientPerson++;
      if (
        clientPersonFilterObject &&
        Object.keys(clientPersonFilterObject).length > 0
      ) {
        fetchClientPersonListing(clientPersonFilterObject);
      } else if (clientPersonSearchValue.value) {
        fetchClientPersonListing(clientPersonSearchValue.value);
      } else {
        fetchClientPersonListing();
      }
    };

    const searchClientPerson = (value) => {
      clientPersonViewMore.value = false;
      pageNumberClientPerson = 0;
      clientPersonFilterObject = {};
      fetchClientPersonListing(value);
    };

    onMounted(() => {
      getAccountData();
    });
    onUnmounted(() => {
      clientPersonData = reactive([]);
      accountListingData = reactive([]);
    });
    let accountCount = ref();
    const getAccountData = async (value) => {
      let listData = [];
      let q = "";
      let payload = {
        q: "",
        pageNo: currentPageNum,
        mode: "LISTING",
      };
      if (currentPageNum > 0) {
        listData = accountListingData;
      } else {
        accountListingData.splice(0);
      }
      if (typeof value === "string") {
        payload = { q: value, pageNo: currentPageNum, mode: "LISTING" };
        console.log("search");
      } else if (typeof value === "object") {
        if (getAccountData.length === 0) {
          listData = getAccountData;
        }
        payload = {
          ...value,
          pageNo: currentPageNum,
          mode: "LISTING",
        };
      }

      loading.value = true;
      await Services.getAccountListing(payload)
        .then((response) => {
          let listData = accountListingData;
          const { data } = response;
          accountCount.value = data[0].count
          data[0].accounts.map((x) => {
            listData.push({
              id: x.account.id,
              key: x.account.id,
              serialNo: "1",
              menuTitle: x.account,
              menu: x.account,
              companyName: x.account ? x.account : "-",
              accountManager: x.accountManagers ? x.accountManagers : "-",

              subTeam: x.subTeams && x.subTeams.length > 1 ? x.subTeams : "-",
              rmtagged:
                x.subTeams.length != 0 && x.subTeams[0].rag.id
                  ? x.subTeams[0].rag.color
                  : "-",
              cic: x.cic ? x.cic : "-",
              taxonomy: x.taxonomy ? x.taxonomy : "-",
              lastMeeting: x.lastMeetingsDays,
              comments: x.comments,
              call: `0987654321`,
              checked: ref(false),
            });
          });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      loading.value = false;
      Object.assign(accountListingData, listData);
    };

    const fetchClientPersonListing = async (value) => {
      Clientloading.value = true;
      let personData = [];
      let payload = {
        q: "",
        pageNo: pageNumberClientPerson,
        mode: "LISTING",
      };
      if (pageNumberClientPerson > 0) {
        personData = clientPersonData;
      } else {
        clientPersonData.splice(0);
      }
      if (typeof value === "string") {
        payload = { q: value, pageNo: pageNumberClientPerson, mode: "LISTING" };
      } else if (typeof value === "object") {
        payload = {
          ...value,
          pageNo: pageNumberClientPerson,
          mode: "LISTING",
        };
      }
      await Services.getClientPersonListing(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data[0].count &&
            data[0].client_persons.map((x) => {
              personData.push({
                clientPersonName: {
                  name: x.user ? x.user.name : "-",
                  designation: x.designation ? x.designation : "-",
                  id: x.id,
                },
                companyName: {
                  name: x.account ? x.account.name : "-",
                  companyId: x.account ? x.account.companyId : "-",
                  id: x.account ? x.account.id : "-",
                },
                Rm: x.relationshipManager,
                subTeam: x.subTeams ? x.subTeams.map((x) => x.name) : "-",
                lastMeeting: x.meetings.lastMeeting
                  ? Utils.displayLongDate(x.meetings.lastMeeting)
                  : "-",
                emailId: x.user.email ? x.user.email : "-",
                callNumber: x.user.contacts ? x.user.contacts[0].phone : "-",
                comments: x.commentsCount ? x.commentsCount : "-",
              });
            });
          clientPersonListCount.value = data[0].count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Clientloading.value = false;
      Object.assign(clientPersonData, personData);
      clientPersonViewMore.value =
        clientPersonListCount.value > clientPersonData.length;
    };

    const showClientPersonFilter = () => {
      clientPersonFilterVisibility.value = true;
    };
    const clientPersonFilterModalOk = (payload, value) => {
      clientPersonViewMore.value = false;
      pageNumberClientPerson = 0;
      fetchClientPersonListing(payload);
      clientPersonFilterVisibility.value = value;
      clientPersonFilterObject = Utils.deepCopyObject(payload);
    };

    const clientPersonFilterModalCancel = (value) => {
      clientPersonFilterVisibility.value = value;
    };

    let TaxoId = ref("");
    const accountFilterVisibility = ref(false);
    const getAccountId = (id) => {
      TaxoId.value = id;
      widgetvisible.value = true;
    };
    const showAccountFilters = () => {
      accountFilterVisibility.value = true;
    };
    const accountFilterModalOk = (payload, value) => {
      currentPageNum = 0;
      getAccountData(payload);
      accountFilterVisibility.value = false;
      listDataObj = Utils.deepCopyObject(payload);
    };

    const accountFilterModalCancel = () => {
      accountFilterVisibility.value = false;
    };

    return {
      rmData,
      accountCount,
      accountFilterVisibility,
      clientPersonFilterVisibility,
      rightSideBarVisibility,
      showRightSideBar,
      TaxoId,
      loading,
      Clientloading,
      indicator,
      listDataObj,
      accountrowSelection,
      fetchAccountId,
      fetchClientId,
      accountSearchValue,
      clientPersonSearchValue,
      accountListingData,
      companyListing,
      onUnmounted,
      searchAccount,
      searchClientPerson,
      showFilterModal,
      filterVisible,
      viewMoreData,
      getAccountId,
      Services,
      onMounted,
      currentPageNum,
      widgetShowModal,
      clientPersonListing,
      getAccountData,
      search: "",
      title: "",
      paginationValues: {},
      checked: true,
      clientrowSelection,
      clientPersonData,
      activeKey: ref("accounts"),
      showAccountFilters,
      accountFilterModalOk,
      accountFilterModalCancel,
      visible,
      widgetvisible,
      showModal,
      hideModal,
      confirm,
      onChangeTab,
      fetchClientPersonListing,
      fetchMoreClientPersons,
      pageNumberClientPerson,
      showClientPersonFilter,
      clientPersonFilterModalOk,
      clientPersonFilterModalCancel,
      clientPersonViewMore,
      clientPersonListCount,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/CompanyList.scss";
</style>