import dayjs from "dayjs";
export default {
  rounding: (params) => {
    return parseFloat(params).toFixed(2);
  },
  displayLongDate: (params) => {
    return dayjs(params).format("DD/MM/YYYY");
  },
  displayShortDate: (params) => {
    return dayjs(params).format("DD/MM/YY");
  },
  dispalyDateMonthYear: (params) => {
    return dayjs(params).format("DD MMM, YYYY");
  },
  displayYearFirst: (params) => {
    return dayjs(params).format("YYYY/MM/DD");
  },
  displayYearFirstNew: (params) => {
    return dayjs(params).format("YYYY-MM-DD");
  },
  deepCopyObject: (params) => {
    return JSON.parse(JSON.stringify(params));
  },
  currencyFormat: (params) => {
    return parseFloat(params)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  },
  displayInvoiceDate: (params) => {
    return dayjs(params).format("DD/MMM/YYYY");
  },
  termArray: (termsData) => {
    let tempData = [];
    termsData.map((x) => {
      let terms;
      if (x.isFixed) {
        terms = x.level + "( FixedFee :" + x.fixedFee + ")";
      } else {
        terms =
          x.level + " (" + x.percentage + "% - Fee Limit : " + x.fee + ")";
      }
      tempData.push({
        label: terms,
        value: x.id,
      });
    });
    return tempData;
  },
  term: (termObject) => {
    let term;
    if (termObject.isFixed) {
      term = termObject.name + "( FixedFee :" + termObject.fixedFee + ")";
    } else {
      term =
        termObject.name +
        " (" +
        termObject.percentage +
        "% - Fee Limit : " +
        termObject.fee +
        ")";
    }
    return term;
  },
};
