<template>
  <div class="modal-taxonomy">
    <div class="modal-sec1">
      <div class="taxonomy-modal-head">
        <div>
          <h2>{{ taxonomyData.company_name }}</h2>
        </div>
        <div class="taxonomy-vacancy-id">{{ taxonomyData.company_id }}</div>
      </div>
      <div class="section-one">
        <div class="heading">
          <div class="minus-btn">
            <MinusOutlined class="svgicon" />
            &nbsp;
          </div>
          <div class="title">
            <h3>Taxonomy</h3>
          </div>
        </div>
        <div class="content-block">
          <div class="content">
            <div class="key-data">Industry :</div>

            <div
              class="value-data"
              v-for="industry in taxonomyData.industry"
              :key="industry"
            >
              {{ industry.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Sector :</div>

            <div
              class="value-data"
              v-for="sector in taxonomyData.sector"
              :key="sector"
            >
              {{ sector.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Practice Area :</div>

            <div
              class="value-data"
              v-for="prArea in taxonomyData.practice_area"
              :key="prArea"
            >
              {{ prArea.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Sub Practice Area :</div>

            <div
              class="value-data"
              v-for="subprArea in taxonomyData.sub_practice_area"
              :key="subprArea"
            >
              {{ subprArea.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Functions :</div>

            <div
              class="value-data"
              v-for="functions in taxonomyData.functions"
              :key="functions"
            >
              {{ functions.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">SF Work Area :</div>

            <div
              class="value-data"
              v-for="sf_work_area in taxonomyData.sf_work_area"
              :key="sf_work_area"
            >
              {{ sf_work_area.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">SSF Tag :</div>

            <div
              class="value-data"
              v-for="ssf_tag in taxonomyData.ssf_tag"
              :key="ssf_tag"
            >
              {{ ssf_tag.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Product :</div>

            <div
              class="value-data"
              v-for="product in taxonomyData.product"
              :key="product"
            >
              {{ product.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Region :</div>

            <div
              class="value-data"
              v-for="region in taxonomyData.region"
              :key="region"
            >
              {{ region.name }}
            </div>
          </div>
          <div class="content">
            <div class="key-data">Segment :</div>

            <div
              class="value-data"
              v-for="segment in taxonomyData.segment"
              :key="segment"
            >
              {{ segment.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-divider />
    <div class="modal-sec-2">
      <div class="taxonomy-count-data">
        <div>
          <center>
            <span class="count">{{ taxonomyData.last_vac_meeting }} Days</span>
            <p class="text">Last Meeting</p>
          </center>
        </div>
        <a-divider
          type="vertical"
          style="height: 30px; background-color: black"
        />
        <div>
          <center>
            <span class="count">{{ taxonomyData.last_vacancy }} days</span>
            <p class="text">Last Vacancy</p>
          </center>
        </div>
      </div>
    </div>

    <div class="modal-sec-3">
      <div class="heading">
        <div class="minus-btn">
          <MinusOutlined class="svgicon" />
        </div>
        &nbsp;
        <div class="title">
          <h3>Vacancies</h3>
        </div>
      </div>
      <div class="vacancy-data">
        <div class="dictionary">
          <div class="dict-data">
            <div class="key-data">36 Month Vacancy:</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.vac_36_months }}</div>
          </div>
          <div class="dict-data">
            <div class="key-data">Total Vacancies:</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.total_vac }}</div>
          </div>
          <div class="dict-data">
            <div class="key-data">Vac. Count:</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.vac_count }}</div>
          </div>
        </div>
        <div class="dictionary-data">
          <div class="dict-data">
            <div class="key-data">Running Vacancies :</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.vac_running }}</div>
          </div>
          <div class="dict-data">
            <div class="key-data">Active Vac. Count :</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.active_vac }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-sec-4">
      <div class="heading">
        <div class="minus-btn">
          <b><MinusOutlined class="svgicon" /></b>
        </div>
        &nbsp;
        <div class="title">
          <h3>Meeting</h3>
        </div>
      </div>
      <div class="meeting-data">
        <div class="dictionary">
          <div class="meeting-dict-data">
            <div class="key-data">Total Client Meetings:</div>
            &nbsp;
            <div class="obj-data">{{ taxonomyData.last_meeting }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MinusOutlined } from "@ant-design/icons-vue";
import Services from "@/services/apis";
import { defineComponent } from "vue";
import { onMounted, reactive } from "vue";
let taxonomyData = reactive({});
let apiData = taxonomyData;
export default defineComponent({
  components: {
    MinusOutlined,
  },
  props: ["id"],
  setup(props) {
    onMounted(() => getAccountTaxonomy(props));
    const getAccountTaxonomy = (props) => {
      Services.getAccountTaxonomy(props.id)
        .then((response) => {
          const { data } = response;
          apiData = {
            company_id: data.account.companyId,
            company_name: data.account.name,
            industry: data.taxonomy.industries,
            sector: data.taxonomy.sectors ? data.taxonomy.sectors : "-",
            practice_area: data.taxonomy.practiceAreas,
            sub_practice_area: data.taxonomy.subPracticeArea,
            functions: data.taxonomy.functions,
            sf_work_area: data.taxonomy.subFunctions,
            ssf_tag: data.taxonomy.subSubFunctions,
            product: data.taxonomy.products,
            region: data.taxonomy.regions,
            segment: data.taxonomy.segments,
            vac_36_months: data.vacancies["36MVacancies"],
            vac_running: data.vacancies.runningVacancies,
            total_vac: data.vacancies.totalVacancies,
            active_vac: data.vacancies.activeVacancies,
            vac_count: data.vacancies.vacancyCount,
            last_vac_meeting: data.vacancies.daysSinceLastMeeting,
            last_meeting: data.meetings.totalClientMeetings,
            last_vacancy: data.vacancies.daysSinceLastVacancy,
          };
          console.log("apidata", apiData);
          Object.assign(taxonomyData, apiData);
          console.log("taxonomyData", taxonomyData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    return {
      getAccountTaxonomy,
      apiData,
      taxonomyData,
    };
  },
});
</script>
<style lang="scss">
@import "@/features/company/styles/widget.scss";
</style>